.loader,
.loader::after {
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
}

.loader {
  display: inline-block;
  position: relative;
  text-indent: -9999em;
  border-top: 0.2rem solid rgb(255 255 255 / 20%);
  border-right: 0.2rem solid rgb(255 255 255 / 20%);
  border-bottom: 0.2rem solid rgb(255 255 255 / 20%);
  border-left: 0.2rem solid #fff;
  transform: translateZ(0);
  animation: loading 1.1s infinite linear;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
