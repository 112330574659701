.formContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: var(--spacer-xs);
  row-gap: 1rem;
}

.checkboxContainer {
  display: flex;
  gap: var(--spacer-r);
  font-size: var(--text-reg--fontSize);
  font-weight: var(--text-med--fontWeight);
  grid-column: 1 / 3;
}

.emailInput {
  border-color: var(--color-structure-80);
  box-sizing: border-box;
}

.errorMessage {
  display: flex;
  margin-top: var(--spacer-s);
  align-items: center;
}

.errorText {
  margin: 0;
  padding-left: var(--spacer-xs);
  font-size: var(--text-xs--fontSize);
  font-weight: var(--text-xs--fontWeight);
  color: var(--color-core-30);
}

.isSubmittingText {
  margin-right: var(--spacer-s);
}

.loggedInButton {
  height: 48px;
  padding: 0 var(--spacer-l);
  box-sizing: border-box;
}

a.loggedInButton:hover {
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .formContainer {
    grid-template-columns: 1fr;
  }

  .checkboxContainer {
    grid-column: 1 / 2;
  }

  .submitButton {
    grid-row: 3 / 4;
  }

  .loggedInButton {
    width: 100%;
  }
}
